import React from 'react';
import { FormattedMessage } from '../../util/reactIntl';
import { ArrowButton } from '../../components';
import { pathByRouteName } from '../../util/routes';
import routeConfiguration from '../../routeConfiguration';
import classNames from 'classnames';

import css from './SearchProfessionalsPage.module.css';

const TopContainer = props => {
  const { history, isMapVisible } = props;

  // Path to SignupPage
  const signupPagePath = pathByRouteName('SignupPage', routeConfiguration());
  return (
    <div
      className={classNames(css.topContainer, {
        [css.topContainerWithMap]: isMapVisible,
      })}
    >
      <div className={css.topContainerWrapper}>
        <div className={css.leftContent}>
          <h1 className={css.topContainerTitle}>
            <FormattedMessage id="SearchProfessionalsPage.topContainerTitle" />
          </h1>
          <p className={css.topContainerText}>
            {isMapVisible ? (
              <FormattedMessage id="SearchProfessionalsPage.topContainerShortText" />
            ) : (
              <FormattedMessage id="SearchProfessionalsPage.topContainerText" />
            )}
          </p>
          <ArrowButton
            className={css.topContainerButton}
            onClick={() => history.push(signupPagePath)}
          >
            <FormattedMessage id="SearchProfessionalsPage.topContainerButton" />
          </ArrowButton>
        </div>
        <div className={classNames(css.rightContent, css.topContainerImage)}>
          <div className={css.topContainerImageBg} />
        </div>
      </div>
    </div>
  );
};

export default TopContainer;
